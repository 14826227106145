<template>
  <!-- 列表 -->
  <div class="list" v-if="isLoaded">
    <!-- 列表项目 -->
    <div class="status-bar flex-center-center" v-if="success">
      <span class="iconfont icon-success"></span>
      <span>签到成功</span>
    </div>
    <div class="status-bar flex-center-center" v-if="!success && errMsg !== ''">
      <span class="iconfont icon-fail"></span>
      <span>{{ errMsg }}</span>
    </div>

    <!-- 列表项目 -->
    <div class="list-item" v-if="success">
      <!-- 图片 -->
      <img v-if="data.activity.picture && data.activity.picture !== ''" :src="data.activity.picture" :onerror="require('@/assets/images/img_fail.png')" />
      <!-- 内容块 -->
      <div class="body">
        <!-- 标题 -->
        <div class="title-line flex-row-between-center">
          <span class="title one-line">{{data.activity.name}}</span>
        </div>
        <!-- 内容 -->
        <div class="content">
          <div>活动时间：{{data.activity.activityTime}}</div>
          <div v-if="data.status === 'Cancel'">取消时间：{{data.canceledAt}}</div>
          <div v-if="data.signupAt">报名时间：{{data.signupAt}}</div>
          <div v-if="data.signinAt">签到时间：{{data.signinAt}}</div>
          <div>活动费用：{{data.activity.isFree ? "免费" : data.activity.cost + "元" }}</div>
          <div>报名姓名：{{data.user.name}}</div>
          <div v-if="data.isMember">所属企业：{{data.member.companyName}}</div>
          <div v-else>所属单位：{{data.member.companyName}}</div>
          <div>联系电话：{{data.user.phoneNumber}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loading from '@/mixins/loading'
import { Toast } from 'mint-ui'

export default {
  name: 'ActivitySignin',

  data () {
    return {
      data: {
        activity: {
          cost: 0
        },
        member: {},
        user: {}
      },
      success: false,
      errMsg: ''
    }
  },

  mixins: [loading],

  created () {
    this.showLoading()
    const scanner = this.$route.query.scanner
    switch (scanner) {
      case 'manager':
        this.activitySignupID = this.$route.query.id
        if (!this.activitySignupID) {
          this.errMsg = '二维码有误'
          Toast(this.errMsg)
          return
        }
        this.getDataByID()
        break
      case 'user':
        this.activityID = this.$route.query.activityID
        if (!this.activityID) {
          this.errMsg = '二维码有误'
          Toast(this.errMsg)
        }
        this.getDataByActivityID()
        break
      default:
        this.errMsg = '二维码有误'
        Toast(this.errMsg)
    }
  },

  methods: {
    // 获取数据
    getDataByID () {
      this.api.getActivitySignup({ id: this.activitySignupID }).then(res => {
        this.data = res.data
        const userInfo = this.$store.state.userInfo
        if (!userInfo || !userInfo.isManager) {
          this.errMsg = '无权限签到'
          Toast(this.errMsg)
          return
        }
        this.signinActivity()
      }).catch(err => {
        if (err.status === 401) {
          this.$store.commit('addLoginCallBack', () => { this.getDataByID() })
        } else {
          this.errMsg = err.data.msg
        }
      }).finally(() => {
        this.hideLoading()
      })
    },
    // 根据活动ID获取获取数据
    getDataByActivityID () {
      this.api.getAllActivitySignupForUser({ id: this.activityID }).then(res => {
        this.data = res.data
        this.signinActivity()
      }).catch((res) => {
        if (res.status === 401) {
          this.$store.commit('addLoginCallBack', () => { this.getDataByActivityID() })
        } else {
          this.errMsg = res.data.msg
        }
      }).finally(() => {
        this.hideLoading()
      })
    },
    signinActivity () {
      this.api.signinActivity({ id: this.data.activitySignupID }).then(res => {
        Toast(res.msg)
        this.success = true
      }).catch(err => {
        if (err.status === 401) {
          this.$store.commit('addLoginCallBack', () => { this.signinActivity() })
        } else {
          this.errMsg = err.data.msg
          if (err.data.code === 40020) this.success = true
        }
      }).finally(() => {
        this.hideLoading()
      })
    }
  }
}
</script>

<style scoped>
.status-bar {
  width: 100%;
  height: 50px;
  font-size: 18px;
  border-radius: 3px;
  color: #fff;
  background: var(--main-color);
  margin-bottom: 15px;
}
.icon-success, .icon-fail {
  font-size: 22px;
  margin-right: 5px;
}

.list {
  margin: 15px;
}
.list-item {
  border: 1px solid #dddddd;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #eeeeee;
}
.list-item + .list-item {
  margin-top: 10px;
}

.list-item > img {
  object-fit: cover;
  width: 100%;
  max-height: 300px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.body {
  padding: 5px 15px;
  font-size: 15px;
  color: var(--normal-font-color);
}

.title-line {
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid #f5f5f5;
}
.title {
  width: 80%;
}

.content {
  padding: 10px;
  font-size: 13px;
  color: #999;
}
.content > div + div {
  margin-top: 5px;
}
</style>
